import axios from 'axios';
import Base from './base';
import User from './user';
import Item from './item';

const SDK = {
	init(opts) {
		const { CancelToken } = axios;
		const CancelTokenSrc = CancelToken.source();
		opts.CancelTokenSrc = CancelTokenSrc.token;
		const client = Base.init(opts);
		return {
			_cancellation: CancelTokenSrc,
			_cancel(message) {
				CancelTokenSrc.cancel(message);
			},
			user: User.init(client),
			item: Item.init(client),
		};
	},
};

export default SDK;
