import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader, Search } from "semantic-ui-react";
import Item from "../../components/item";
import {toggleLoading, itemThunk} from '../../slices/item_slices';

const ItemsPage = () => {
  const loading = useSelector((state) => state.item.loading);
  const items = useSelector((state) => state.item.items);
  const [filteredItems, setFilteredItems] = useState(items);
  const [text, setText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoading());
    dispatch(itemThunk());
  }, [])

  useEffect(() => {
    setFilteredItems(items);
  }, [items])

  const itemRenderer = () => {
    let itemsToRender = [];
    filteredItems.forEach((element, index) => {
      itemsToRender.push(<Item key={index} element={element}/>)
    });
    return itemsToRender;
  }

  const handleSearchChange = (e, {value}) => {
    let existing_value = text;
    setText(value);
    if(value !== "" & value !== existing_value) {
      setFilteredItems(items.filter((el) => {
        if(el["itemCode"].toLowerCase().includes(value.toLowerCase())) return true;
        if(el['itemName'].toLowerCase().includes(value.toLowerCase())) return true;
        return false;
      }));
    } else {
      setFilteredItems(items);
    }
  }

  return (
    loading
    ?
      <div>
        <Loader active/>
      </div> 
    : 
      <div style={{flex: 1, overflowY: "inherit", marginTop: 10}}>
        <Grid columns={1}>
          <Grid.Column>
            <Search
              input={{ fluid: true }}
              showNoResults={false}
              loading={loading}
              placeholder='Search Items using Name / Code'
              onSearchChange={handleSearchChange}
              value={text}
              style={{width: "100%", }}
            />
          </Grid.Column>
        </Grid>
        <div style={{overflowY: "auto", marginTop: 10, whiteSpace: "nowrap"}}>
          <Grid columns={3} doubling stackable>
            {itemRenderer()}
          </Grid>
        </div>
      </div>
  )
};

export default ItemsPage;
