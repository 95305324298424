import React, { useEffect, useState } from 'react';
import { Buffer } from "buffer";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setEncodedSelectedCompany, logout } from '../slices/login_slices'
import { Menu, Grid, Image, Icon, Dropdown, Segment, Sidebar, Button, GridColumn } from 'semantic-ui-react'
import CustomMenu from './CustomMenu';
import Page from './CustomPage';

// CSS and Other Imports
import pjson from '../../package.json';
import 'semantic-ui-css/semantic.min.css';

function HomePage(){
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [activeItem, setActiveItem] = useState('items');
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = pjson.tab_name;
    var asp_token = window.localStorage.getItem('asp_token');
    if(!asp_token){
      navigate('/login');
    }
    else
    {
      dispatch(setEncodedSelectedCompany(asp_token));
      var company = JSON.parse(Buffer.from(asp_token, 'base64').toString());
      setUserName(company.userName);
      setCompanyName(company.companyName);
    }
  }, [dispatch, navigate])

  const handleLogout = () => {
    window.localStorage.removeItem('asp_token');
    dispatch(logout());
    navigate("/login");
  }

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  }

  return (
    <div style={{flex:1, overflow: "hidden"}}>
      <Grid style={{ height: "100vh", margin: 0 }}>
        <Grid.Row style={{ padding: 0, height: '6%' }}>
          <Grid.Column style={{ padding: 0 }}>
            <Menu inverted borderless style={{ borderRadius: 0, height: "100%" ,width: '100%', marginBottom: 0, backgroundColor: "#008080" }}>
              <Menu.Item header style={{ marginLeft: 10 }}>
                <Grid style={{ padding: 0 }}>
                  <Grid.Row style={{ padding: 0 }} columns={2}>
                    <Grid.Column style={{ padding: 0 }} only='mobile'>
                      <Image src={require('../public/logo.png')} size='mini' style={{ paddingTop: 3 }}/>
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }} only='mobile'>
                      <Button icon inverted style={{ border: "none" }} onClick={() => setSideBarVisible(!sideBarVisible)}>
                        <Icon name="sidebar" style={{ marginLeft: 10, lineHeight: 1 }}/>
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid style={{ padding: 0, margin: 0, marginLeft: 10 }}>
                  <Grid.Row style={{ padding: 0 }} columns={2}>
                    <Grid.Column only="tablet computer" style={{ padding: 0 }}>
                      <Image src={require('../public/logo.png')} size='mini'/>
                    </Grid.Column>
                    <Grid.Column only="tablet computer" style={{ textSizeAdjust: "auto", padding: 0, lineHeight: 2 }}>
                      {pjson.tab_name}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Item>
              <Menu.Menu position='right' style={{ marginRight: 10 }}>
                <Dropdown
                  item
                  text={userName + " / " + companyName}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item text="Logout" onClick={handleLogout}/>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0, height: '94%' }}>
          <GridColumn only="mobile">
            <Sidebar.Pushable>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={sideBarVisible}
                onHide={() => setSideBarVisible(!sideBarVisible)}
              >
                <CustomMenu activeItem={activeItem} handleItemClick={handleItemClick} />
              </Sidebar>
              <Sidebar.Pusher
                dimmed={sideBarVisible}
                style={{ minHeight: "100vh" }}
              >
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </GridColumn>
          <Grid.Column only='tablet computer' tablet={3} computer={2} style={{ padding: 0 }}>
            <Menu secondary vertical inverted style={{ width: '100%', height: '100%', margin: 0, backgroundColor: "#008080" }}>
              <CustomMenu activeItem={activeItem} handleItemClick={handleItemClick} />
            </Menu>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={13} computer={14} style={{ padding: 0 }}>
            <Segment style={{ height: "100%", width: "100%", borderRadius: 0}}>
              {Page(activeItem)}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
};

export default HomePage;