import { Button, Grid, Header, Segment } from "semantic-ui-react";

function Item({element, responder}) {
  const inventoryUOM = element.inventryUom;
  const itemCode = element.itemCode;
  const itemName = element.itemName;
  const onHand = element.onHand;
  const unitPrice = element.unitPrice;

  return (
    <Grid.Column>
      <Segment color="teal" padded>
        <Header as="h5" textAlign="center">
          {itemCode}<br />{itemName}
        </Header>
        <Header.Subheader style={{textAlign: "center"}}>
          <Segment.Group horizontal>
            <Segment>
              <Header as="h5">UOM</Header>
              <p>{inventoryUOM}</p>
            </Segment>
            <Segment>
              <Header as="h5">OnHand</Header>
              <p>{onHand}</p>
            </Segment>
            <Segment>
              <Header as="h5">Price</Header>
              <p>{unitPrice}</p>
            </Segment>
          </Segment.Group>
          <Button
            color="teal"
            onClick={() => {
              responder(element);
            }}
          >
            Check Availability
          </Button>
        </Header.Subheader>
      </Segment>
    </Grid.Column>
  );
}

export default Item;
