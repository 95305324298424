import { Routes, Route } from "react-router-dom";
import Login from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgetPasswordPage";
import Home from "./pages/HomePage";

export default function Routing() {
  return (
      <Routes>
        <Route index exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
  );
}