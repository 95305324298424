const Item = {
	init(initializedBase) {
		const base = initializedBase;
		return {
			items() {
				return base.get('/ItemOrder/');
			}
		};
	},
};

export default Item;