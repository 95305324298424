const User = {
	init(initializedBase) {
		const base = initializedBase;
		return {
			login(encoded_data) {
				return base.get('/useraccount/' + encoded_data);
			}
		};
	},
};

export default User;
