import SDK from './sdk';
const pjson = require('../../package.json')

const obj = {
	init() {
		return SDK.init({
			baseUrl: pjson.backend_url,
			asp_token: window.localStorage.getItem('asp_token') || null,
		});
	},
};

export default obj;
