import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Buffer } from "buffer";
import appsdk from "../base";

export const loginThunk = createAsyncThunk("login/user", async (data) => {
  const sdk = appsdk.init();
  const response = await sdk.user.login(data);
  return response.data;
});

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    data: "",
    loading: false,
    companies: [],
    is_logged_in: false,
    selected_company: {},
    encoded_selected_company: "",
    forgot_password_loading: false,
  },
  reducers: {
    toggleLoading: (state) => {
      if (state.loading) state.loading = false;
      else state.loading = true;
    },
    toggleForgotPasswordLoading: (state) => {
      if (state.forgot_password_loading) state.forgot_password_loading = false;
      else state.forgot_password_loading = true;
    },
    setEncodedSelectedCompany: (state, action) => {
      state.encoded_selected_company = action.payload;
      state.selected_company = Buffer.from(action.payload, "base64").toString();
    },
    companySelector: (state, action) => {
      var selected_company = state.companies.filter((el) => {
        if (el.slpCode + ":" + el.userId === action.payload) return true;
        else return false;
      });
      if (selected_company.length > 0) {
        state.selected_company = selected_company[0];
        state.encoded_selected_company = Buffer.from(
          JSON.stringify(selected_company[0])
        ).toString("base64");
        window.localStorage.setItem(
          "asp_token",
          Buffer.from(JSON.stringify(selected_company[0])).toString("base64")
        );
      }
    },
    logout: (state) => {
      state.data = "";
      state.loading = false;
      state.companies = [];
      state.is_logged_in = false;
      state.selected_company = {};
      state.encoded_selected_company = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.companies = action.payload;
        if (action.payload.length > 1) {
          state.loading = false;
          state.is_logged_in = true;
        } else {
          state.loading = false;
          state.selected_company = action.payload[0];
          state.encoded_selected_company = Buffer.from(
            JSON.stringify(action.payload[0])
          ).toString("base64");
          window.localStorage.setItem(
            "asp_token",
            Buffer.from(JSON.stringify(action.payload[0])).toString("base64")
          );
        }
      } else {
        state.loading = false;
      }
    });
  },
});

export const {
  toggleLoading,
  companySelector,
  setEncodedSelectedCompany,
  logout,
  toggleForgotPasswordLoading,
} = loginSlice.actions;

export default loginSlice.reducer;
