import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Buffer } from "buffer";
import { Button, Form, Grid, Header, Segment, Image } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoading, loginThunk, companySelector, setEncodedSelectedCompany } from '../slices/login_slices'
import Particle from '../components/particle';

// CSS and Other Imports
import pjson from '../../package.json';
import 'semantic-ui-css/semantic.min.css';

function LoginPage() {
  // State Management
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const loading = useSelector((state) => state.login.loading)
  const is_logged_in = useSelector((state) => state.login.is_logged_in)
  const options = useSelector((state) => state.login.companies)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = pjson.tab_name;
    var asp_token = window.localStorage.getItem('asp_token');
    if(asp_token){
      dispatch(setEncodedSelectedCompany(asp_token));
      navigate('/');
    }
  })

  const handleSubmit = () => {
    dispatch(toggleLoading());
    dispatch(loginThunk(Buffer.from(`{"username": "${username}", "password": "${password}"}`).toString('base64')))
  }

  return (
    <>
      <Particle />
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Segment style={{ padding: 25 }}>
            <Header as='h2' color='teal' textAlign='center' style={{ marginBottom: 30 }}>
              <Image src={require('../public/logo.png')}/>
              <Grid style={{ marginTop: 10 }}>
                <Grid.Row>
                  <Grid.Column>
                    Login
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          {is_logged_in ? 
            <>
              <Form size='large'>
                <Form.Select
                  fluid
                  placeholder='Select Company'
                  options={options.map((el) => {
                    return { 
                      key: el.slpCode + ":" + el.userId, 
                      value: el.slpCode + ":" + el.userId,  
                      text: el.companyName 
                    }
                  })}
                  onChange={(e, {value}) => {dispatch(companySelector(value)); navigate("/")}}
                />
              </Form>
            </> : 
            <>
              <Form size='large' onSubmit={handleSubmit}>
                <Form.Input 
                  fluid 
                  icon='user' 
                  value={username}
                  iconPosition='left' 
                  placeholder='Username'
                  required={true}
                  onChange={(event) => {setUsername(event.target.value)}}
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  value={password}
                  placeholder='Password'
                  type='password'
                  required={true}
                  onChange={(event) => {setPassword(event.target.value)}}
                />
                <Grid columns={2} style={{ marginBottom: 15 }}>
                  <Grid.Row style={{ paddingTop: 5}}>
                    <Grid.Column></Grid.Column>
                    <Grid.Column><Link style={{ float: 'right' }} to='/forgot-password'>Forgot Password?</Link></Grid.Column>
                    </Grid.Row>
                </Grid>
                <Button color='teal' fluid size='large' style={{ marginTop: 20 }} loading={loading} disabled={loading}>
                  Login
                </Button>
              </Form>
            </>
          }
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default LoginPage;
