import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Header, Image, Message, Segment, Icon } from 'semantic-ui-react'
import { toggleForgotPasswordLoading, setEncodedSelectedCompany, logout } from '../slices/login_slices'
import Particle from '../components/particle'

// CSS and Other Imports
import pjson from '../../package.json';
import 'semantic-ui-css/semantic.min.css';

function ForgetPasswordPage() {
  const loading = useSelector((state) => state.login.forgot_password_loading);
  const [username, setUsername] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    toggleForgotPasswordLoading();
    setTimeout(() => {
      toggleForgotPasswordLoading();
    }, 2000);
  }

  useEffect(() => {
    document.title = pjson.tab_name;
    var asp_token = window.localStorage.getItem('asp_token');
    if(asp_token){
      dispatch(setEncodedSelectedCompany(asp_token));
      navigate('/');
    }
  })

  const handleBack = () => {
    dispatch(logout);
    navigate('/login');
  }

  return (
    <>
      <Particle />
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Segment style={{ padding: 25 }}>
            <Grid>
              <Grid.Column textAlign='left'>
                <Button icon style={{ backgroundColor: "white", color: "black"}} onClick={handleBack}>
                  <Icon name="arrow left" />
                </Button>
              </Grid.Column>
            </Grid>
            <Header as='h2' color='teal' textAlign='center' style={{ marginBottom: 30 }}>
              <Image src={require('../public/logo.png')}/>
              <Grid style={{ marginTop: 10 }}>
                <Grid.Row>
                  <Grid.Column>
                    Reset your password
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
            <Message floating>Enter the username address associated with your account and we'll send a link to reset your password
            </Message>
            <Form size='large' onSubmit={handleSubmit}>
              <Form.Input 
                  fluid 
                  icon='user' 
                  value={username}
                  iconPosition='left' 
                  placeholder='Username'
                  required={true}
                  onChange={(event) => {setUsername(event.target.value)}}
                />
                <Button color='teal' fluid size='large' style={{ marginTop: 20 }} loading={loading} disabled={loading}>
                    Send Magic Link
                </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default ForgetPasswordPage;