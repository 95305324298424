import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appsdk from "../base";

export const itemThunk = createAsyncThunk("login/user", async (data) => {
  const sdk = appsdk.init();
  const response = await sdk.item.items();
  return response.data;
});

export const itemSlice = createSlice({
  name: "item",
  initialState: {
    loading: false,
    items: []
  },
  reducers: {
    toggleLoading: (state) => {
      if (state.loading) state.loading = false;
      else state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(itemThunk.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
    })
  },
});

export const { toggleLoading } = itemSlice.actions;

export default itemSlice.reducer;
