import {Menu} from 'semantic-ui-react';

function CustomMenu({activeItem, handleItemClick}) {
    return (
      <>
        <Menu.Item>
          <Menu.Header>Master Lookup</Menu.Header>
          <Menu.Item
            name='items'
            active={activeItem === 'items'}
            onClick={handleItemClick}
          />
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Transactions</Menu.Header>
          <Menu.Item
            name='sales quotation'
            active={activeItem === 'sales quotation'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name='sales order'
            active={activeItem === 'sales order'}
            onClick={handleItemClick}
          />
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Account</Menu.Header>
          <Menu.Item
            name='create account'
            active={activeItem === 'create account'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name='customer detail'
            active={activeItem === 'customers detail'}
            onClick={handleItemClick}
          />
        </Menu.Item>
      </>
    )
}

export default CustomMenu;