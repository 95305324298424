/* eslint no-console: 0 */
import axios from 'axios';

const Base =  {
	init(opts) {
		let baseUrl = opts.baseUrl;
		let asp_token = opts.asp_token;
		const cancelToken = opts.CancelTokenSrc;
		if (Object.hasOwnProperty.call(opts, 'baseUrl')) {
			({ baseUrl } = opts);
		}

		return {
			request(url, method, queryParams, data, contentType, responseType, options) {
				if (!url) {
					throw new Error('Request - URL not defined');
				}

				if (!contentType) {
					contentType = 'application/json';
				}

				if (!queryParams) {
					queryParams = {};
				}

				if (!data) {
					data = {};
				}

				url = baseUrl + url;

				switch (method) {
					case 'GET':
						return this.get(url, queryParams, contentType, responseType);

					case 'POST':
						return this.post(url, queryParams, data, contentType, options);

					case 'PUT':
						return this.put(url, queryParams, data, contentType, responseType);

					case 'DELETE':
						return this.del(url, queryParams, data, contentType);

					default:
						throw new Error('Request method not defined');
				}
			},

			get(url, queryParams, contentType, responseType) {
				const config = {
					cancelToken,
					headers: {
						Accept: contentType,
						asp_token: asp_token,
					},
					params: queryParams,
				};
				if (responseType) {
					config.responseType = responseType;
				}
				try {
					return axios.get(baseUrl + url, config);
				} catch (error) {
					if (axios.isCancel(error)) {
						console.log(error.message);
					} else {
						throw error;
					}
				}
			},

			post(url, queryParams, data, contentType, options) {
				const config = {
					cancelToken,
					headers: {
						Accept: contentType,
					},
					params: queryParams,
				};

				if (options) {
					config.onUploadProgress = options.onUploadProgress;
				}

				const accessToken = window.localStorage.getItem('accessToken');
				if (accessToken) {
					config.headers.Authorization = `Token ${accessToken}`;
				}

				try {
					return axios.post(url, data, config);
				} catch (error) {
					if (error.response.data.message === 'token is not from your User Pool') {
						window.localStorage.removeItem('accessToken');
						window.location.href = '/login';
					}

					if (axios.isCancel(error)) {
						console.log(error.message);
					} else { throw error; }
				}
			},

			put(url, queryParams, data, contentType, responseType) {
				const config = {
					cancelToken,
					headers: {
						Accept: contentType,
					},
					params: queryParams,
				};
				if (responseType) {
					config.responseType = responseType;
				}

				const accessToken = window.localStorage.getItem('accessToken');
				if (accessToken) {
					config.headers.Authorization = `Token ${accessToken}`;
				}

				try {
					return axios.put(url, data, config);
				} catch (error) {
					if (error.response.data.message === 'token is not from your User Pool') {
						window.localStorage.removeItem('accessToken');
						window.location.href = '/login';
					}

					if (axios.isCancel(error)) {
						console.log(error.message);
					} else { throw error; }
				}
			},

			del(url, queryParams, contentType) {
				const config = {
					cancelToken,
					headers: {
						Accept: contentType,
					},
					params: queryParams,
				};

				const accessToken = window.localStorage.getItem('accessToken');
				if (accessToken) {
					config.headers.Authorization = `Token ${accessToken}`;
				}

				try {
					return axios.delete(url, config);
				} catch (error) {
					if (error.response.data.message.message === 'token is not from your User Pool') {
						window.localStorage.removeItem('accessToken');
						window.location.href = '/login';
					}

					if (axios.isCancel(error)) {
						console.log(error.message);
					} else { throw error; }
				}
			},

			url() {
				return baseUrl;
			},

			customRequest(url, queryParams, contentType) {
				if (!contentType) {
					contentType = 'application/javascript';
				}

				if (!queryParams) {
					queryParams = {};
				}

				const config = {
					cancelToken,
					headers: {
						Accept: contentType,
					},
					params: queryParams,
				};

				const accessToken = window.localStorage.getItem('accessToken');
				if (accessToken) {
					config.headers.Authorization = `Token ${accessToken}`;
				}

				url = `${window.location.origin}${url}`;

				try {
					return axios.delete(url, config);
				} catch (error) {
					if (error.response.data.message === 'token is not from your User Pool') {
						window.localStorage.removeItem('accessToken');
						window.location.href = '/login';
					}

					if (axios.isCancel(error)) {
						console.log(error.message);
					} else { throw error; }
				}
			},
		};
	},
};

export default Base;